<template>
  <div class="batch-search">
            <div class="top-box">
              <div class="top-title">客户统计</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">项目</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="project"
                      v-loading="projectListLoading"
                      element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in projectList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学届</div>
                  <div class="input-box">
                      <el-input v-model="level" placeholder="请输入学生学届" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">省份</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="province"
                      v-loading="provinceListLoading"
                      element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in provinceList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">科类</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="subject"
                    v-loading="subjectListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in subjectList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="1" class="form-item">
                 <div class="action-link" @click="handlerFormCollapse()">{{formCollapse?'收起':'展开'}}</div>
              </el-col>
               <div v-if="formCollapse">
                <el-col :span="6" class="form-item">
                    <div class="label">机构</div>
                    <div class="input-box">
                        <el-input v-model="organization" placeholder="请输入报名机构" class="form-control"></el-input>
                    </div>
                </el-col>
               </div>
            </el-row>

            <!--  -->
            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe class="table-body" ref="tableData"
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="tableLoading">
                <el-table-column prop="year" label="序号"></el-table-column>
                <el-table-column prop="province" label="机构"></el-table-column>
                <el-table-column prop="patriarchName" label="家长姓名"></el-table-column>
                <el-table-column prop="batch" label="孩子姓名"></el-table-column>
                <el-table-column prop="score" label="性别"></el-table-column>
                <el-table-column prop="type" label="学籍"></el-table-column>
                <el-table-column prop="type" label="名族"></el-table-column>
                <el-table-column prop="type" label="学校"></el-table-column>
                <el-table-column prop="type" label="学届"></el-table-column>
                <el-table-column prop="subject" label="科类"></el-table-column>
                <el-table-column prop="type" label="联系电话"></el-table-column>
                <el-table-column prop="project" label="项目"></el-table-column>
                <el-table-column prop="type" label="转案时间"></el-table-column>
                <el-table-column prop="type" label="续费时间"></el-table-column>
                <el-table-column prop="type" label="缴费金额"></el-table-column>
                <el-table-column prop="type" label="销售顾问"></el-table-column>
                <el-table-column prop="type" width="100" label="crm订单号"></el-table-column>
                <el-table-column prop="type" width="120" label="crm合同编号"></el-table-column>
                <el-table-column prop="type" width="120" label="crm订单状态"></el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="tableTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
          </div>
</template>
<script>
export default {
  name: "batch",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      formCollapse: false,
      projectListLoading:false,
      provinceListLoading:false,
      subjectListLoading:false,
      tableLoading:false,
      subject:"",
      level:"",
      project:"",
      province:"",
      organization:"",
      provinceList:[],
      subjectList:[],
      projectList:[],
      tableData: [],
    };
  },
  methods: {
    initSelectData(){
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        if (res.data) {
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //获取科类
      this.$http.post(this.$apis.getCourseList).then((res) => {
        if (res.data) {
          this.subjectList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initTableData(){
      this.tableLoading = true;
      this.tableData = [
        {
          year: "2020",
          province: "黑龙江省",
          subject: "文科",
          batch: "本科一批",
          score: 483,
          type: "旧高报",
        },
        {
          year: "2020",
          province: "黑龙江省",
          subject: "文科",
          batch: "本科一批",
          score: 483,
          type: "旧高报",
        },
        {
          year: "2020",
          province: "黑龙江省",
          subject: "文科",
          batch: "本科一批",
          score: 483,
          type: "旧高报",
        },
      ];
      this.tableLoading = false;
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    },
    handlerFormCollapse(){
      this.formCollapse = !this.formCollapse;
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    }
  },
  mounted(){
    this.initSelectData();
    this.initTableData();
  },
  activated() {
    this.$refs.tableData.doLayout()
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-table__body-wrapper {
    height: 90% !important;
}
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
        height: 55px;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 14px;
          cursor:pointer;
          color: #ff450b;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
}
</style>